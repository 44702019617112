.viewport {
  --viewport-padding: 25px;
  padding: var(--viewport-padding);
  width: min(100%, 390px);
}

.close {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.root:hover .close {
  opacity: 1;
}

.root[data-state='open'] {
  animation: slideIn 150ms ease-in;
}

.root[data-state='closed'] {
  animation: hide 100ms ease-in;
}

.root[data-swipe='move'] {
  transform: translateX(var(--radix-toast-swipe-move-x));
}

.root[data-swipe='cancel'] {
  transform: translateX(0);
  transition: transform 200ms ease-out;
}

.root[data-swipe='end'] {
  animation: swipeOut 100ms ease-out;
}

@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
  to {
    transform: translateX(0);
  }
}

@keyframes swipeOut {
  from {
    transform: translateX(var(--radix-toast-swipe-end-x));
  }
  to {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
}
