input.todo-input[type='checkbox'] {
  @apply grid place-content-center appearance-none w-[18px] h-[18px] box-content rounded
    border border-current text-primary-600;

  background: linear-gradient(
      0deg,
      rgb(var(--primary-600) / 0.06),
      rgb(var(--primary-600) / 0.06)
    ),
    rgb(var(--white));
}

input.todo-input[type='checkbox']::before {
  @apply bg-primary-600;
  content: '';
  transform: scale(0);
}

input.todo-input[type='checkbox']::before {
  mask: url(../../assets/icons/check.svg);
  mask-size: cover;
  background-color: white;
}

input.todo-input[type='checkbox']::before {
  @apply w-5 h-5 rounded-sm;
}

input.todo-input[type='checkbox']:hover {
  @apply bg-primary-600/20;
}

input.todo-input[type='checkbox']:active:not(:disabled),
input.todo-input[type='checkbox']:focus {
  @apply outline-1 outline-primary-700;
}

input.todo-input[type='checkbox']:checked::before {
  transform: scale(1);
}

input.todo-input[type='checkbox']:checked {
  @apply bg-primary-600;
}

input.todo-input[type='checkbox']:disabled {
  @apply bg-neutral-200 border-neutral-400;
}

@keyframes strike {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.todo-text {
  position: relative;
}

.todo-text::after {
  @apply bg-text-600;

  content: ' ';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  animation-name: strike;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
