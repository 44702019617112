:root {
  --primary-700: 1 53 144;
  --primary-600: 15 103 255;
  --primary-500: 66 144 243;
  --primary-300: 108 212 255;
  --primary-200: 188 244 245;
  --primary-dark: 13 88 217;
  --primary-black: 0 0 0;
  --primary-red: 255 107 108;
  --background: 242 244 250;
  --background-lighter: 235 237 245;
  --background-darker: 225 227 237;
  --text-solid: 44 37 67;
  --text-70: #6b667b;
  --text-link: #0f67ff;
  --text-200: 233 233 233;
  --text-300: 192 190 199;
  --text-500: 149 146 161;
  --text-600: 102 102 102;
  --text-700: 107 102 123;
  --text-green: 93 211 158;
  --card-bg: 2 34 88;
  --panel-bg: 196 213 243;
  --panel-yellow: 255 210 79;
  --white: 255 255 255;
  --gray-300: 213 211 217;
  --gray-600: 164 164 164;
}

body {
  margin: 0;
  font-family: 'Space Grotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  background-color: rgb(var(--background));
  height: 100vh;
}

.drop-column:has(.dragging),
.drop-column:has(.dragging) * {
  box-shadow: none !important;
}

.drop-column:has(.dragging) .todo-box::before,
.drop-column:has(.dragging) .todo-box::after {
  content: none !important;
}

.drop-column:has(.dragging) button {
  display: none !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
